import React from "react"
import AuthContent from "../gatsby-theme-wild-child/components/auth/AuthContent"
import { Box } from "@chakra-ui/react"
import Section from "gatsby-theme-wild-child/src/components/Section"

function Feedback() {
  return (
    <AuthContent>
      <Section>
        <Box mb={8} w="full">
          <iframe
            style={{
              background: "#FFFFFF",
              border: "none",
              borderRadius: 2,
              boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
              width: "100%",
              minHeight: "100vh",
            }}
            width={640}
            height={480}
            src="https://charts.mongodb.com/charts-virtuzone-yvtcb/embed/charts?id=642e2bce-6b40-4de8-87db-946d8b0a9c72&maxDataAge=300&theme=light&autoRefresh=true"
          />
        </Box>
        <Box mb={8}>
          <iframe
            style={{
              background: "#FFFFFF",
              border: "none",
              borderRadius: 2,
              boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
            }}
            width={640}
            height={480}
            src="https://charts.mongodb.com/charts-virtuzone-yvtcb/embed/charts?id=642e2acb-f4c8-47c7-87ea-5f3c4fdd1015&maxDataAge=3600&theme=light&autoRefresh=true"
          />
        </Box>
      </Section>
    </AuthContent>
  )
}

export default Feedback

export const Head = () => (
  <>
    <title>
      TaxGPT by Virtuzone | Feedback
    </title>
    <meta
      name="facebook-domain-verification"
      content="0qis6k25boblo98elnosvjz5ws7gtm"
    />
    <meta name="google-site-verification" content="fdkrhMVPDQOPHf3A4czsCkEuBbJ_lkvh8yj01E6fn4Q" />
  </>
)
